import { useEffect, useRef, useState } from "react";

import Grid from "@mui/material/Grid";

import { sendMessageEvent, sendVisitEvent } from "api/events";
import ChatMessageSend from "components/chat/ChatMessageSend";
import ChatMessages from "components/chat/ChatMessages";
import { AgentDetails } from "types/agent";
import { Message } from "types/chat";
import { getAgentDetails } from "utils/agent-details";
import { getSystemMessages, getSystemResponseMessage, getUserMessage } from "utils/chat";
import ChatHeader from "components/header/ChatHeader";
import { VERTICAL_PADDING } from "./constants";
import ChatReset from "components/chat/ChatReset";

const ChatPage = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [agent, setAgent] = useState<AgentDetails | undefined>(undefined);
  const [userAgentOverride, setUserAgentOverride] = useState<string>("");
  const [showReset, setShowReset] = useState<boolean>(false);
  const bottomRef = useRef<HTMLDivElement>(null);
  const changesCountRef = useRef(0);
  const originUrl = window.location.origin;
  const urlPath = window.location.pathname + window.location.search + window.location.hash;

  useEffect(() => {
    const fetchAgentDetails = async () => {
      const agent = await getAgentDetails(originUrl);
      if (agent) {
        setAgent(agent);
        sendVisitEvent(agent, originUrl, urlPath, userAgentOverride);
        setMessages([...getSystemMessages(agent)]);
      }
    };

    fetchAgentDetails();
  }, [originUrl, urlPath]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [messages]);

  useEffect(() => {
    changesCountRef.current += 1;
    if (changesCountRef.current > 1) {
      if (agent) {
        // Equivalent to refreshing the page.
        sendVisitEvent(agent, originUrl, urlPath, userAgentOverride);
        setMessages([...getSystemMessages(agent, userAgentOverride)]);
      }
    }
  }, [userAgentOverride]);

  const handleSendMessage = (text: string) => {
    if (text.trim()) {
      const newMessage: Message = getUserMessage(text);
      setMessages([...messages, newMessage]);

      // Introduce a delay before adding the system response message
      setTimeout(() => {
        setMessages((prevMessages) => [...prevMessages, getSystemResponseMessage()]);
        setShowReset(true);
      }, 600);

      sendMessageEvent(newMessage, agent, originUrl, urlPath, userAgentOverride);
    }
  };

  const handleReset = () => {
    if (agent) {
      setMessages([...getSystemMessages(agent, userAgentOverride)]);
      setShowReset(false);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh", py: `${VERTICAL_PADDING}px` }}
    >
      <Grid item xs={11} md={7}>
        {/* TODO: Find a better way than passing those props */}
        <ChatHeader
          userAgentOverride={userAgentOverride}
          setUserAgentOverride={setUserAgentOverride}
          companyLogo={agent?.logo}
          headerBackground={agent?.headerBackground}
        />
      </Grid>
      <Grid item xs={11} md={7}>
        <ChatMessages messages={messages} ref={bottomRef} companyAvatar={agent?.avatar} />
        {showReset ? (
          <ChatReset handleReset={handleReset} />
        ) : (
          <ChatMessageSend handleSendMessage={handleSendMessage} />
        )}
      </Grid>
    </Grid>
  );
};

export default ChatPage;
